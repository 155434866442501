import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes, LINE_ITEM_HOUR } from '../../util/types';
import { priorityShowTypes } from '../../config/configListing';

import css from './OrderBreakdown.module.css';

const LineItemBasePriceMaybe = props => {
  const { lineItems, code, intl , transaction } = props;
  const isNightly = code === LINE_ITEM_NIGHT;
  const isDaily = code === LINE_ITEM_DAY;
  const isHourly = code === LINE_ITEM_HOUR;
  const translationKey = isNightly
    ? 'OrderBreakdown.baseUnitNight'
    : isDaily
    ? 'OrderBreakdown.baseUnitDay'
    : isHourly
    ? 'OrderBreakdown.baseUnitHour'
    : 'OrderBreakdown.baseUnitQuantity';

  // Find correct line-item for given code prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/hour', or 'line-item/item'
  // These are defined in '../../util/types';
  const unitPurchase = lineItems.find(item => item.code === code && !item.reversal);

  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  const listingId = transaction?.listing?.id?.uuid;
  const { cartItems } = transaction?.attributes?.protectedData || {};  
  const eachItem = (listingId && Array.isArray(cartItems) && cartItems.length) ? cartItems.filter((st)=> st.listingId==listingId) : [];
  const { customizationText='' } = listingId && Array.isArray(cartItems) && cartItems.length ? cartItems[0] : {};
  const listingTitle = transaction?.listing?.attributes?.publicData?.productTitle
  const titleVariantText = eachItem && eachItem.length ? priorityShowTypes.filter((st)=> eachItem[0][st]).map((st)=> `${eachItem[0][st]}` ).join(" | ") : "";

  return quantity && total ? ( <>
      <div className={css.lineItem}>
      <span className={css.itemLabel}>
        {listingTitle ? 
       <span className={css.titleBox}> <b>{listingTitle}</b>{titleVariantText ? " | " + titleVariantText : ""}</span>
        : <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />}
      </span>
      {listingTitle ? <span className={css.quantityValue}>QTY {quantity}</span> : null}
      <span className={css.itemValue}>{total}</span>
    </div>
    {customizationText && typeof customizationText=="string" ? <input className={css.inputText} type="textarea" value={customizationText}  /> : null}
    </>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  // lineItems: propTypes.lineItems.isRequired,
  // code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
